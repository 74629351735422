.container {
  font-family: Arial, sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: white;
}
.header {
  background-color: #444;
  color: white;
  padding: 20px;
  border-radius: 10px;
}
.main {
  margin: 20px;
  font-size: 20px;
  padding: 20px;
  background-color: #222;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
}
.footer {
  background-color: #333;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
}